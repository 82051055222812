<template src="./SignIn.html"></template>
<style></style>

<script>
import axios from 'axios'
import {isArray} from 'lodash'

export default {
    name: 'sign-in',

    data() {
        return {
            email: process.env.VUE_APP_LOGIN || null,
            password: process.env.VUE_APP_PASSWORD || null,
            errorMessage: null,
            pending: false
        }
    },

    created() {
        if (localStorage.getItem('BotXESGToken')) {
            this.$router.push('/app')
        }
    },

    mounted() {
        this.$nextTick(() => this.$refs.email.focus())
    },

    methods: {
        async signIn() {
            this.pending = true
            this.errorMessage = null

            try {
                const {data: {token}} = await axios.request({
                    url: `${process.env.VUE_APP_API_URL}/login`,
                    method: 'POST',
                    data: {email: this.email, password: this.password}
                })
                localStorage.setItem('BotXESGToken', token)

                this.$store.commit('setApi')

                setTimeout(() => {
                    this.$router.push('/app/dashboard')
                }, 2000)
            } catch (e) {
                const message = e?.response?.data?.message || ['You set wrong credentials.']
                this.errorMessage = isArray(message) ? message.join(', ') : message
                this.pending = false
            }

            // setTimeout(() => {
            //   if (this.email === 'admin' && this.password === 'admin') {
            //     this.$router.push('/esg/dashboard')
            //   } else {
            //     this.errorMessage = 'You set wrong credentials.'
            //     this.pending = false
            //   }
            // }, 1750)
        }
    }
}
</script>
